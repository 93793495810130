import React, { useState } from "react";
import "./footer.css";
import { NavLink } from "react-router-dom";
import StringData from "../String.json";
import emailjs from "@emailjs/browser";

const Footer = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    //Your EmailJS service ID, template ID and Public Key
    const serviceId = "service_1mttvkp";
    const templateId = "template_flgg01j";
    const publicKey = "gywGFHuS1zapb5wUb";

    //create a new object that contains dynamic template params
    const tempplateParams = {
      from_name: username,
      from_email: email,
      to_name: "Muktinath Krishi Company Limited",
      message: message,
    };

    //send the email using EmailJS
    emailjs
      .send(serviceId, templateId, tempplateParams, publicKey)
      .then((response) => {
        console.log("Email sent successfully!", response);
        setUsername("");
        setEmail("");
        setMessage("");
      })
      .catch((error) => {
        console.error("Error sending email", error);
      });
  };

  return (
    <>
      <div className="container-fluid footer-main">
        <div className="container">
          <footer className="py-5">
            <div className="row footer-content mb-5 d-flex">
              <div className="col-md-4 footer-left">
                <div className="footer-logo text-start">
                  <NavLink to="/">
                    <img
                      src={StringData.footersection.companylogo}
                      alt={StringData.footersection.company_name}
                    />
                  </NavLink>
                </div>
                <ul className="nav flex-column">
                  <li className="nav-item mt-4 mb-2">
                    <NavLink className="nav-link p-0 text-decoration-none text-start text-white">
                      {StringData.footersection.address}
                    </NavLink>
                  </li>
                  <li className="nav-item mb-2">
                    <NavLink className="nav-link p-0 text-decoration-none text-start text-white">
                      {StringData.footersection.phonenumber}
                    </NavLink>
                  </li>
                  <li className="nav-item mb-2">
                    <NavLink className="nav-link p-0 text-decoration-none text-start text-white">
                      {StringData.footersection.email}
                    </NavLink>
                  </li>
                </ul>
              </div>

              <div className="col-md-6 gap-4 footer-right">
                <form onSubmit={handleSubmit}>
                  <h4>Let's connect and work together</h4>
                  <p>Any queries and feedback are accepted.</p>
                  <div className="d-flex flex-column gap-3">
                    <div className="d-flex gap-4">
                      <input
                        type="text"
                        class="form-control"
                        id="floatingInputGroup1"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                      <input
                        type="email"
                        class="form-control"
                        id="floatingInput"
                        placeholder="name@example.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div>
                      <textarea
                        class="form-control"
                        placeholder="Leave your message here ..."
                        id="floatingTextarea2"
                        style={{ height: "100px" }}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>
                    </div>
                    <div className="col send-cta">
                      <button className="btn btn-success " type="submit">
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="d-flex flex-column gap-4 flex-sm-row justify-content-between py-4  border-top">
              <p className="text-white-50">
                &copy; 2024 {StringData.footersection.company_name}. All rights
                reserved.
              </p>
              <ul className="list-unstyled d-flex justify-content-center align-items-center">
                <li className="ms-3">
                  <a className="link-body-emphasis" href="#instagram">
                    <i
                      className="bi bi-instagram text-white-50"
                      width="24"
                      height="24"
                    ></i>
                  </a>
                </li>
                <li className="ms-3">
                  <a className="link-body-emphasis" href="#facebook">
                    <i
                      className="bi bi-facebook text-white-50"
                      width="24"
                      height="24"
                    ></i>
                  </a>
                </li>
                <li className="ms-3">
                  <a className="link-body-emphasis" href="#twitter">
                    <i
                      className="bi bi-twitter-x text-white-50"
                      width="24"
                      height="24"
                    ></i>
                  </a>
                </li>
                <li className="ms-3">
                  <a className="link-body-emphasis" href="#linkedin">
                    <i
                      className="bi bi-linkedin text-white-50"
                      width="24"
                      height="24"
                    ></i>
                  </a>
                </li>
              </ul>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Footer;
