import React from "react";
import "./aboutsection.css";
import StringData from "../../String.json";

const QualityProgram = () => {
  const linearGradient =
    "linear-gradient(148deg, rgba(28,28,28,0.80) 0%, rgba(28,28,28,0.80) 100%)";
  const imageUrl = `url('${StringData.aboutsection.backgroundImage}')`;
  const backgroundImg = {
    background: `${linearGradient},${imageUrl} no-repeat center center / cover`,
  };
  return (
    <>
      <div className="container-fluid about-main" style={backgroundImg}>
        <div className="container about-section">
          <div className="row about-content d-flex justify-content-center align-content-center">
            <div className="col-md-8 text-content pt-5 pb-5 d-flex flex-column gap-4 ">
              <h1 className="about-title">About Us</h1>
              <p className="about-para">
                <b>{StringData.aboutsection.company_name}</b>
                {StringData.aboutsection.description}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QualityProgram;
