import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Navbar from "./components/Nabvar/Navbar";
import BodyRoute from "./components/BodyRoute/BodyRoute";
import Footer from "./components/Footer/Footer";
import Cursor from "./components/Cursor";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Cursor />
        <Navbar />
        <BodyRoute />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
